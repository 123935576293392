import * as React from 'react'
import { Link } from 'gatsby'

import {notfound} from './404.module.scss'
import Seo from '../components/seo'

const NotFoundPage = () => {
  return (
    <div className={notfound}>
      <Seo title="Errore 404"/>

      <title>Errore 404</title>
      <h3>Errore 404</h3>
      <p>Pagina non trovata</p>
      <Link to="/">Torna alla home</Link>
    </div>
  )
}

export default NotFoundPage
